<template>
    <div>
        <section class="position-relative">
            <b-container class="my-5">
                <b-row class="justify-content-md-center">
                    <b-col md="12" lg="9" class="py-15">
                        <div class="flags">
                            <div @click="setLocale('en')" :class="{ active: $i18n.locale === 'en' }">
                                <img src="/images/us.svg"  alt="us" />
                                English
                            </div>
                            <div @click="setLocale('es')" :class="{ active: $i18n.locale === 'es' }">
                                <img src="/images/es.svg"  alt="es" />
                                Español
                            </div>
                        </div>
                    </b-col>


<!--                    <b-col lg="3">-->
<!--                        <b-form-group class="mb-4 position-relative">-->
<!--                            <b-form-select type="text"-->
<!--                                           :options="options" :class="`hover-show-tooltip jd-input radius-0`"-->
<!--                                           v-model="selected" @change="setLocale(selected)">-->
<!--                            </b-form-select>-->
<!--                        </b-form-group>-->
<!--                    </b-col>-->
                </b-row>
                <h1 class="jd-text-dark jd-font-bold jd-text-36 jd-text-25__mobile text-center">
                    {{ $t('message.employment_form') }}</h1>
                <b-row class="mt-5 justify-content-md-center">
                    <b-col md="12" lg="9" class="py-15">
                        <employment-form></employment-form>
                    </b-col>
                </b-row>
            </b-container>

        </section>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import EmploymentForm from "@/components/EmploymentForm.vue";

export default {
    data() {
        return {
            selected: 'en',
            options: [
                { text: 'English', value: 'en' },
                { text: 'Español', value: 'es'},
            ],
        }
    },
    components: {EmploymentForm},
    mounted() {
        this.$root.$emit('hideLoader');
    },
    methods: {
        setLocale(selected) {
            this.$i18n.locale = selected;
        }
    }
}
</script>

<style scoped lang="scss">
.py-15 {
  padding: 15px;
}

.flags {
    display: flex;
    margin-bottom: 30px;
    > div {
        display: flex;
        align-items: center;
        margin-right: 20px;
        cursor: pointer;
        img {
            width: 24px;
            margin-right: 10px;
        }

        &:hover, &.active {
            text-decoration: underline;
            color: #184782;
        }
    }
}
</style>
